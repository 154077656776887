import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Compnama from "./Compnama";

export default function Comptopkiper() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [kiper, setKiper] = useState([]);
  const [jml, setJml] = useState(10);
  const [posisi, setPosisi] = useState("");

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarirtopkiper");
      setKiper(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <hr />
      <div className="table-responsive">
        <small className="text-secondary">Posisi top kiper rank solo</small>
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr className="table-primary">
              <th scope="col">No</th>
              <th scope="col">Nama</th>
              <th scope="col">
                P
              </th>
              <th  scope="col">
                W
              </th>
              <th  scope="col">
                D
              </th>
              <th  scope="col">
                L
              </th>
              <th scope="col">
                Pts
              </th>
            </tr>
          </thead>
          <tbody>
            {kiper.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user
                      ? "table-primary fw-bold"
                      : index + 1 > jml
                      ? ""
                      : ""
                  }
                >
                  <th id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold" scope="row">{index + 1}</th>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">
                    <Compnama iduser={data.id_user} />
                  </td>
                  {/* <td>{data.persen.substr(0, 5)}%</td> */}
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.play}</td>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.win}</td>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.draw}</td>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.lost}</td>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.point}</td>
                </tr>
              );
            })}
            {/* {kiper.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user
                      ? "table-primary fw-bold"
                      : ""
                  }
                >
                  <th className="table-danger" scope="row">
                    {index + 1}
                  </th>
                  <td className="table-success">
                    <Compnama iduser={data.id_user} />
                  </td>
                  <td className="table-warning">{data.jml}</td>
                </tr>
              );
            })} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
