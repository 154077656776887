import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import Loadketentuanbooking from "../skeleton/Loadketentuanbooking";
import Loadjambooking from "../skeleton/Loadjambooking";
import Loadformbooking from "../skeleton/loadformbooking";
import Pembayaran from "./Pembayaran";
import Databookinglapangan from "./Databookinglapangan";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Datalapangan from "./Datalapangan";
import Footerbooking from "./Footerbooking";
import StatusBookedrankteam from "./StatusBookedrankteam";
import Lapanganlainya from "./Lapanganlainya";
import Satujam from "./Satujam";
import { Alert } from "react-bootstrap";

export default function Formbooking() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { idlapangan } = useParams();
  const { tglsrc2 } = useParams();
  const [jambooking, setJambooking] = useState("");
  const [tglbooking, setTglbooking] = useState("");
  const [namateam, setNamateam] = useState("");
  const [totalHarga, setTotalharga] = useState("");
  const [namaLapangan, setTNamalapangan] = useState("");
  const [wa, setWa] = useState("");
  const [alert, setAlert] = useState("");
  const [jammain, setJammain] = useState([]);
  const [idjambooking, setIdjambooking] = useState(0);
  const [cekbookinglap, setCekbookinglap] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadjam, setLoadjam] = useState(false);
  const [alerttglsudahlewat, setAlerttglsudahlewat] = useState(false);
  const [alerttglsudahlewatwhiterat, setAlerttglsudahlewatwhiterat] =
    useState(false);
  const [hiddenbutton, setHiddenbutton] = useState(true);
  const [hiddenbutton2, setHiddenbutton2] = useState(true);
  const [pagebooking, setPagebooking] = useState(true);
  const [pagedatabooking, setPagedatabooking] = useState(false);
  const [pagejam, setPagajam] = useState(true);
  const [lapanganlainya, setLapanganlainya] = useState([]);
  const [sistempembayaran, setSistempembayaran] = useState("");
  const [tutup, setTutup] = useState([]);
  const [btnclose, setBtnclose] = useState(true);
  const [chekout, setChekout] = useState(0);
  const [tglclose, setTglclose] = useState("");
  const [dateclose, setDateclose] = useState("");
  const [satujam, setSatujam] = useState([]);
  const [ceksatujam, setCeksatujam] = useState(false);
  const [cekduajam, setCekduajam] = useState(false);
  const [datatutup, setDatatutup] = useState([]);
  const [statusButton, setStatusButton] = useState(0);
  const [show, setShow] = useState(true);

  const resvonsive = {
    0: {
      items: 1.2,
    },
    600: {
      items: 1.2,
    },
    1000: {
      items: 1.2,
    },
  };

  const resvonsive2 = {
    0: {
      items: 2.5,
    },
    600: {
      items: 2.5,
    },
    1000: {
      items: 2.5,
    },
  };

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const [tglsrc, setTglsrc] = useState(format_tgl);
  const [tglsekarang, setTglsekarang] = useState(format_tgl);

  const notify = () =>
    toast.success("Booking lapangan anda berhasil !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notifyCekbooking = () => {
    toast.error("Jam booking ini sudah di gunakan !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const notifyCekbookingclose = () => {
    setHiddenbutton(false);
    toast.error("Jam booking saat ini sedang di tutup !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const getLapangan = async (id) => {
    if (id == null) {
      var idlap = idlapangan;
    } else {
      var idlap = id;
    }
    try {
      const response = await axios.get(urlapi + "Lapangan?id=" + idlap);
      setTotalharga(response.data.harga_perjam);
      setTNamalapangan(response.data.lapangan);
    } catch (error) {
      console.log(error.message);
    }
  };

  const cekBookingTgl = (e) => {
    setTglbooking(e.target.value);
    actionCekTgl(e.target.value);
  };

  const cekBookingJam = (e) => {
    setJambooking(e.target.value);
    actionCekJam(e.target.value);
  };

  const actionCekTgl = async (tgl) => {
    await axios
      .post(urlapi + "Cekbooking", {
        jam: jambooking,
        tgl: tgl,
        id_lapangan: idlapangan,
      })
      .then((response) => {
        setAlert(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionCekJam = async (jam) => {
    await axios
      .post(urlapi + "Cekbooking", {
        jam: jam,
        tgl: tglbooking,
        id_lapangan: idlapangan,
      })
      .then((response) => {
        setAlert(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PayBooking = async () => {
    await axios
      .post(urlapi + "pay", {
        total: totalHarga,
        team: namateam,
        nama: localStorage.getItem("nama"),
        email: localStorage.getItem("email"),
      })
      .then((response) => {
        const token = response.data.token;
        if (token) {
          window.snap.pay(token, {
            onSuccess: (result) => {
              console.log(result);
              addBooking(result);
            },
            onPending: (result) => {
              console.log(result.status_message);
              addBooking(result);
            },
            onError: (result) => {
              console.log(result.status_message);
            },
          });
        }
      });

    const addBooking = async (result) => {
      await axios
        .post(urlapi + "Addbooking", {
          jam: idjambooking,
          tgl: tglsrc,
          team: namateam,
          id_lapangan: idlapangan,
          kode_status: result.status_code,
        })
        .then((response) => {
          setJambooking("Pilih jam booking");
          setNamateam("");
          setTglbooking("");
          notify();
          cekBookinglapangan(tglsrc);
          closeBooking(tglsrc);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  const handleButtonbooking = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Cekbookinglapangan2?idlap=" +
          idlapangan +
          "&tgl=" +
          tglsrc +
          "&idjam=" +
          idjambooking
      );
      if (response.data.status == true) {
        notifyCekbooking();
      } else {
        PayBooking();
      }
    } catch (error) {}
  };

  const cekBookinglapangan = async (tgl, id) => {
    if (id == null) {
      var idlap = idlapangan;
    } else {
      var idlap = id;
    }
    try {
      const response = await axios.get(
        urlapi + "Cekbookinglapangan?id_lap=" + idlap + "&tgl=" + tgl
      );
      setCekbookinglap(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const getJammain = async (tgl, id) => {
    if (id == null) {
      var idlap = idlapangan;
    } else {
      var idlap = id;
    }
    try {
      const response = await axios.get(
        urlapi + "Jammain3?tgl=" + tgl + "&&lapangan=" + idlap
      );
      console.log();
      
      setLoadjam(true);
      setJammain(response.data);
      setLoad(true);
    } catch (error) {
      setLoad(false);
      setLoadjam(false);
    }
  };

  const handleTglbooking = (tgl) => {
    setLoadjam(false);
    getJammain(tgl);
    setTglsrc(tgl);
    cekBookinglapangan(tgl);
    closeBooking(tgl);
    cekbooksatujam(idlapangan, tgl);
    getdatasatujam(idlapangan, tgl);
    tutupjammain(idlapangan, tgl);
    if (tgl < tglsekarang) {
      setAlerttglsudahlewat(true);
    } else if (idlapangan == "1" && tgl > dateclose) {
      setAlerttglsudahlewatwhiterat(true);
    } else if (idlapangan == "5" && tgl > dateclose) {
      setAlerttglsudahlewatwhiterat(true);
    } else if (idlapangan == "8" && tgl > tglclose) {
      setAlerttglsudahlewatwhiterat(true);
    } else {
      setAlerttglsudahlewat(false);
      setAlerttglsudahlewatwhiterat(false);
    }
  };

  const handleJambooking = (id, harga) => {
    setIdjambooking(id);
    cekdataBooking(id);
    cekcloseBooking(id);
    setTotalharga(harga);
  };

  const tutupjammain = async (idlapangan, tgl) => {
    try {
      const response = await axios.get(
        urlapi + "Tutupjammain?idlap=" + idlapangan + "&&tgl=" + tgl
      );
      // console.log(response.data);
      setDatatutup(response.data);
    } catch (error) {}
  };

  const cekdataBooking = async (id) => {
    try {
      const response = await axios.get(
        urlapi +
          "Cekbookinglapangan2?idlap=" +
          idlapangan +
          "&tgl=" +
          tglsrc +
          "&idjam=" +
          id
      );
      // console.log(response.data.data.iduser);
      if (response.data.data.iduser == "Memberkarir team") {
        cekMemberkarirteam(response.data.data.kode_booking);
      } else {
        if (response.data.status == true) {
          // console.log(response.data.status);
          notifyCekbooking();
          setStatusButton(1);
          setHiddenbutton(false);
        } else {
          console.log("hello");

          setHiddenbutton(true);
          setStatusButton(0);
        }
      }
    } catch (error) {
      setHiddenbutton(true);
      setStatusButton(0);
    }
  };

  const cekMemberkarirteam = async (kodebooking) => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingrankteambooking?kode_main=" + kodebooking
      );
      if (response.data.status == true) {
        setStatusButton(1);
        setHiddenbutton(false);
        notifyCekbooking();
      } else {
        setHiddenbutton(true);
        setStatusButton(0);
      }
    } catch (error) {
      setHiddenbutton(true);
      setStatusButton(0);
    }
  };

  const cekcloseBooking = async (id) => {
    try {
      const response = await axios.get(
        urlapi +
          "Closejambooking2?lap=" +
          idlapangan +
          "&tgl=" +
          tglsrc +
          "&idjam=" +
          id
      );

      if (response.data.status == true) {
        setHiddenbutton(false);
        setBtnclose(false);
        notifyCekbookingclose();
      } else {
        setHiddenbutton(true);
        setBtnclose(true);
      }
    } catch (error) {}
  };

  const Time = new Date();
  const hour = ("0" + Time.getHours()).slice(-2);
  const menit = Time.getMinutes();
  const jam = hour + "." + menit;

  const showBooking = () => {
    setPagebooking(true);
    setPagedatabooking(false);
  };

  const showDatabooking = () => {
    setPagebooking(false);
    setPagedatabooking(true);
  };

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const getlapanganlainya = async (id) => {
    if (id == null) {
      var idlap = idlapangan;
    } else {
      var idlap = id;
    }
    const response = await axios.get(urlapi + "Lapanganlainya?lap=" + idlap);
    try {
      setLapanganlainya(response.data);
    } catch (error) {}
  };

  const handlelainya = (id) => {
    setLoadjam(false);
    showBooking();
    getlapanganlainya(id);
    getLapangan(id);
    getJammain(tglsrc, id);
    cekBookinglapangan(tglsrc, id);
    closeBooking(tglsrc, id);
    setAlerttglsudahlewatwhiterat(false);
    cekbooksatujam(id, tglsrc);
    getdatasatujam(id, tglsrc);
  };

  const closeBooking = async (tgl) => {
    try {
      const response = await axios.get(
        urlapi + "Closejambooking?tgl=" + tgl + "&&lap=" + idlapangan
      );
      // console.log(response.data);
      setTutup(response.data);
    } catch (error) {}
  };

  const closewt = async () => {
    try {
      const response = await axios.get(urlapi + "Getclosewt");
      setTglclose(response.data.tgl_close);
      // console.log(response.data);
    } catch (error) {}
  };

  const getfiturchekout = async () => {
    try {
      const response = await axios.get(urlapi + "Fiturchekout");
      setChekout(response.data.status);
      // console.log(response.data.status);
    } catch (error) {}
  };

  const getclosetgllapangan = async () => {
    try {
      const response = await axios.get(urlapi + "Closelapangan");
      // console.log(response.data);
      setDateclose(response.data.date);
    } catch (error) {}
  };

  const getdatasatujam = async (idlapangan, tgl) => {
    try {
      const response = await axios.get(
        urlapi + "Jammainsatujam?idlap=" + idlapangan + "&&tgl=" + tgl
      );
      setSatujam(response.data);
    } catch (error) {}
  };

  const cekbooksatujam = async (idlapangan, tglsrc) => {
    try {
      const response = await axios.get(
        urlapi + "Cekbooksatujam?idlap=" + idlapangan + "&&tgl=" + tglsrc
      );
      console.log(response.data);
      if (response.data.status == "false") {
        setCeksatujam(false);
        setCekduajam(false);
      } else if (response.data.status == "true") {
        setCekduajam(false);
        setCeksatujam(true);
      } else if (response.data.status == "2 jam") {
        setCekduajam(true);
        setCeksatujam(false);
      } else if (response.data.status == "23-24") {
        setCeksatujam(true);
        setCekduajam(false);
      } else if (response.data.status == "22-23") {
        setCeksatujam(true);
        setCekduajam(false);
      } else {
        setCeksatujam(false);
        setCekduajam(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    console.log(chekout);

    getfiturchekout();
    closewt();
    getclosetgllapangan();
    setTimeout(() => {
      getLapangan();
      getJammain(tglsekarang);
      cekBookinglapangan(tglsrc);
      getlapanganlainya();
      closeBooking(tglsrc);
      getdatasatujam(idlapangan, tglsrc);
      cekbooksatujam(idlapangan, tglsrc);
      tutupjammain(idlapangan, tglsrc);
    }, 200);
  }, []);

  return (
    <div>
      <Navbar judul="Booking" aicon="true" />
      <div className="card container mt-5">
        {load ? (
          <div className="card my-3 shadow" style={{ border: "none" }}>
            <div className="card-body">
              <p className="fw-bold">Ketentuan booking</p>
              <p className="text-secondary">
                {" "}
                Ketentuan dalam membooking lapangan adalah harus mengatur jam
                booking seefesien mungkin, Pastikan team dan lawan anda dapat
                hadir tepak waktu, dan harus membayar uang booking sesuai yang
                tertera di aplikasi
              </p>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={4}
                nav={false}
                responsive={resvonsive}
                dotsEach
                autoplay
              >
                <img
                  src="/img/bennerbooking.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "5px" }}
                ></img>
                <img
                  src="/img/dp.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "5px" }}
                ></img>
              </OwlCarousel>
            </div>
          </div>
        ) : (
          <Loadketentuanbooking />
        )}
        {load ? (
          <>
            {" "}
            <div>
              <div class="alert alert-primary" role="alert">
                <small className="fw-bold">
                  Jadwal booking yang sudah di booking tidak dapat di cancel,
                  mohon perhatikan waktu dan tanggal saat booking lapangan
                </small>
              </div>

              {localStorage.getItem('tamu') != null ? <>
                 <Alert variant="warning" onClose={() => setShow(false)} dismissible>
                      <Alert.Heading>
                        <b> <small>Anda dalam mode tamu ! </small></b>
                      </Alert.Heading>
                      <p>
                        Buat akun medan mini soccer anda sekarang & dapatkan voucher gratis 1 kali booking lapangan untuk per-10 kali booking lapangan menggunakan akun medan mini soccer anda.
                        <br />
                        <Link to='/register' style={{ textDecoration : 'none' }}>Buat akun anda sekarang</Link>
                      </p>
                </Alert>
              </> : <>
                  <Alert variant="success" onClose={() => setShow(false)} dismissible>
                      <Alert.Heading>
                      <b> <small>Hello { localStorage.getItem('nama')} ! </small></b>
                      </Alert.Heading>
                      <p className="">
                       Ayooo booking lapangan sebanyak mungkin & dapatkan voucher gratis booking lapangan
                       
                      </p>
                </Alert>
              </>}

            </div>
          </>
        ) : (
          ""
        )}

        {loadjam ? (
          <div className="card my-3 shadow" style={{ border: "none" }}>
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3">
                <small
                  className={`fw-bold ${pagebooking ? "text-primary" : ""}`}
                  onClick={() => showBooking()}
                  style={{ cursor: "pointer" }}
                >
                  <i className="far fa-calendar-days"></i> Jadwal Booking{" "}
                </small>

                <small
                  className={`fw-bold ${pagedatabooking ? "text-primary" : ""}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => showDatabooking()}
                >
                  <i className="far fa-futbol"></i> Lapangan Lainya
                </small>
              </div>

              <div className={pagedatabooking ? "" : "d-none"}>
                {/* <Databookinglapangan /> */}
                {lapanganlainya.map((lpl, index) => {
                  return (
                    <Link
                      key={index}
                      onClick={() => handlelainya(lpl.id)}
                      to={"/formbooking/" + lpl.id}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card cardBooking shadow mt-3">
                        <div className="row g-0">
                          <div className="col-6 col-md-5">
                            <img
                              src={lpl.gambar}
                              className="img-fluid"
                              alt="Responsive image"
                            />
                          </div>
                          <div className="col-6 col-md-7">
                            <div className="card-body d-flex flex-column">
                              <div className="h-100">
                                <label className="fw-bold text-secondary">
                                  {lpl.lapangan}
                                </label>
                                <br></br>
                                <p className="text-secondary">
                                  {" "}
                                  {lpl.pasilitas}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
                <br></br>
                <br></br>
              </div>

              <div className={pagebooking ? "" : "d-none"}>
                <input
                  className="form-control"
                  value={tglsrc}
                  type="date"
                  onChange={(e) => handleTglbooking(e.target.value)}
                  min="2023.09-06"
                />
                <hr />

                {/* <Lapanganlainya /> */}

                {/* <div className="card mb-2">
                  <div className="card-body">
                    <small className="fw-bold">
                      <i className="fas fa-futbol"></i> Lapangan lainya{" "}
                    </small>

                    <OwlCarousel
                      className="owl-theme mt-2"
                      loop
                      margin={1}
                      dotsClass="false"
                      responsive={resvonsive2}
                    >
                      <div className="card">
                        <img
                          src="/img/imgbd.png"
                          className="img-fluid"
                          alt="Responsive image"
                          style={{ height: "100px" }}
                        />
                      </div>

                      <div className="card">
                        <img
                          src="/img/imgrt.png"
                          className="img-fluid"
                          alt="Responsive image"
                          style={{ height: "100px" }}
                        />
                      </div>

                      <div className="card">
                        <img
                          src="/img/imgwr.png"
                          className="img-fluid"
                          alt="Responsive image"
                          style={{ height: "100px" }}
                        />
                      </div>
                    </OwlCarousel>
                  </div>
                </div> */}
                <div className="card">
                  <div
                    className="card-header"
                    onClick={() => setPagajam(!pagejam)}
                  >
                    <div className="d-flex justify-content-between">
                      <strong> {namaLapangan}</strong>
                      <i
                        className={
                          pagejam
                            ? "fas fa-circle-chevron-down"
                            : "fas fa-circle-chevron-up"
                        }
                      ></i>
                    </div>
                  </div>
                  <div className={pagejam ? "card-body" : "d-none"}>
                    <p
                      className={
                        alerttglsudahlewat
                          ? "text-center text-secondary"
                          : "d-none"
                      }
                      style={{ marginTop: "100px", marginBottom: "100px" }}
                    >
                      <strong>Mohon Maaf {localStorage.getItem("nama")}</strong>
                      <br />
                      Tanggal yang ada pilih sudah lewat
                    </p>
                    <p
                      className={
                        alerttglsudahlewatwhiterat
                          ? "text-center text-secondary"
                          : "d-none"
                      }
                      style={{ marginTop: "100px", marginBottom: "100px" }}
                    >
                      <strong>Mohon Maaf {localStorage.getItem("nama")}</strong>
                      <br />
                      Untuk saat ini jam booking lapangan belum tersedia
                    </p>
                    {jammain.map((jm, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            jam > jm.jam_mulai && tglsrc == tglsekarang
                              ? "d-none"
                              : tglsrc < tglsekarang
                              ? "d-none"
                              : tglsrc > tglclose && idlapangan == "8"
                              ? "d-none"
                              : tglsrc > dateclose && idlapangan == "1"
                              ? "d-none"
                              : tglsrc > dateclose && idlapangan == "5"
                              ? "d-none"
                              : ceksatujam == true && jm.jam_mulai == "22.00"
                              ? "d-none"
                              : tglsrc == datatutup.tgl &&
                                idlapangan == datatutup.id_lap
                              ? "d-none"
                              : ""
                          }
                        >
                          <div
                            className={
                              idjambooking == jm.id
                                ? "card mt-2 border-primary"
                                : "card mt-2"
                            }
                            disabled
                            onClick={() =>
                              handleJambooking(jm.id, jm.harga_diskon)
                            }
                            key={jm.id}
                          >
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <p className="fw-bold">{jm.jam_mulai} WIB</p>
                                <p className="fw-bold text-center">
                                  {jm.status == 0 ? (
                                    <>
                                      <span class="badge bg-info">2 JAM</span>
                                    </>
                                  ) : (
                                    <>
                                      <span class="badge bg-info">1 JAM</span>
                                    </>
                                  )}
                                  {tutup.map((tt, index) => {
                                    return (
                                      <p key={index} className="text-danger">
                                        {tt.id_jam == jm.id ? "Close" : ""}
                                      </p>
                                    );
                                  })}
                                  {cekbookinglap.map((bk, index) => {
                                    return (
                                      <div key={index}>
                                        {bk.jam_mulai == jm.jam_mulai &&
                                        bk.status_pembayaran == "200" ? (
                                          <>
                                            {bk.user == "Memberkarir team" ? (
                                              <>
                                                {" "}
                                                <label className="text-danger">
                                                  {" "}
                                                  {/* Member karir */}
                                                  <StatusBookedrankteam
                                                    kode_main={bk.kode_booking}
                                                  />
                                                </label>
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <label className="text-danger">
                                                  Booked
                                                </label>
                                              </>
                                            )}

                                            <br></br>
                                            <small>
                                              {localStorage.getItem("id") ==
                                              bk.iduser ? (
                                                <small>
                                                  {localStorage.getItem("nama")}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </small>
                                          </>
                                        ) : localStorage.getItem("id") ==
                                            bk.iduser &&
                                          bk.jam_mulai == jm.jam_mulai &&
                                          bk.status_pembayaran == "201" ? (
                                          <small className="text-danger">
                                            Menunggu Approve
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  })}
                                </p>

                                <p className="fw-bold">{jm.jam_berakhir} WIB</p>

                                <div
                                  className="form-check form-check-inline"
                                  style={{ display: "none" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={jm.id}
                                    id="flexCheckDefault"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                {jm.harga == jm.harga_diskon ? (
                                  <>
                                    <div></div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <small className="fw-bold text-secondary">
                                        <s>{formatrupiah(jm.harga)}</s>
                                      </small>{" "}
                                      {jm.harga2 == "" || jm.harga2 == null ? (
                                        <></>
                                      ) : (
                                        <>
                                          <br />
                                          <small className="fw-bold text-secondary">
                                            <s>{formatrupiah(jm.harga2)}</s>
                                          </small>{" "}
                                        </>
                                      )}
                                      <br />
                                    </div>
                                  </>
                                )}

                                <div
                                  className={
                                    jm.harga == jm.harga_diskon ? "" : ""
                                  }
                                >
                                  {jm.time != '' ? <> <span className={'badge text-bg-'+jm.color}>{jm.time} </span></>  : ''}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <span className="badge text-bg-dark fw-bold">
                                  {formatrupiah(jm.harga_diskon)}
                                </span>{" "}
                                {jm.diskon != '' ? <> <span className="badge text-bg-dark fw-bold">Disc { jm.diskon }%</span></> : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* -----Satujam-------- */}
                    {cekduajam}

                    <div
                      className={
                        cekduajam == true
                          ? "d-none"
                          : tglsrc < tglsekarang
                          ? "d-none"
                          : tglsrc > dateclose && idlapangan == "1"
                          ? "d-none"
                          : tglsrc > dateclose && idlapangan == "5"
                          ? "d-none"
                          : tglsrc > tglclose && idlapangan == "8"
                          ? "d-none"
                          : tglsrc == datatutup.tgl &&
                            idlapangan == datatutup.id_lap
                          ? "d-none"
                          : ""
                      }
                    >
                      {satujam.map((jm, index) => {
                        return (
                          <div key={index}>
                            <div
                              className={
                                idjambooking == jm.id
                                  ? "card mt-2 border-primary"
                                  : "card mt-2"
                              }
                              disabled
                              onClick={() =>
                                handleJambooking(jm.id, jm.harga_diskon)
                              }
                            >
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <p>{jm.jam_mulai} WIB</p>
                                  <p className="fw-bold text-center">
                                    {jm.status == 0 ? (
                                      <>
                                        <span class="badge bg-info">2 JAM</span>
                                      </>
                                    ) : (
                                      <>
                                        <span class="badge bg-success">
                                          1 JAM
                                        </span>
                                      </>
                                    )}
                                    {tutup.map((tt, index) => {
                                      return (
                                        <p key={index} className="text-danger">
                                          {tt.id_jam == jm.id ? "Close" : ""}
                                        </p>
                                      );
                                    })}

                                    {cekbookinglap.map((bk, index) => {
                                      return (
                                        <div key={index}>
                                          {bk.jam_mulai == jm.jam_mulai &&
                                          bk.jam_selesai == jm.jam_berakhir &&
                                          bk.status_pembayaran == "200" ? (
                                            <>
                                              {bk.user == "Memberkarir team" ? (
                                                <>
                                                  {" "}
                                                  <label className="text-danger">
                                                    {" "}
                                                    {/* Member karir */}
                                                    <StatusBookedrankteam
                                                      kode_main={
                                                        bk.kode_booking
                                                      }
                                                      jam_mulai={jm.jam_mulai}
                                                    />
                                                  </label>
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <label className="text-danger">
                                                    Booked
                                                  </label>
                                                </>
                                              )}

                                              <br></br>
                                              <small>
                                                {localStorage.getItem("id") ==
                                                bk.iduser ? (
                                                  <small>
                                                    {localStorage.getItem(
                                                      "nama"
                                                    )}
                                                  </small>
                                                ) : (
                                                  ""
                                                )}
                                              </small>
                                            </>
                                          ) : localStorage.getItem("id") ==
                                              bk.iduser &&
                                            bk.jam_mulai == jm.jam_mulai &&
                                            bk.status_pembayaran == "201" ? (
                                            <small className="text-danger">
                                              Menunggu Approve
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    })}
                                  </p>
                                  <p>{jm.jam_berakhir} WIB</p>
                                </div>

                                <div className="d-flex justify-content-between">
                                  {jm.harga == jm.harga_diskon ? (
                                    <>
                                      <div></div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <small className="fw-bold text-secondary">
                                          <s>{formatrupiah(jm.harga)}</s>
                                        </small>{" "}
                                      </div>
                                    </>
                                  )}

                                  <div
                                    className={
                                      jm.harga == jm.harga_diskon ? "" : ""
                                    }
                                  >
                                    <span
                                      className={
                                        jm.time == "Promo"
                                          ? "badge text-bg-primary"
                                          : jm.time == "Hot"
                                          ? "badge text-bg-danger"
                                          : "badge text-bg-success"
                                      }
                                      style={{ width: "50px" }}
                                    >
                                      {jm.time}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                  <span className="badge text-bg-dark fw-bold">
                                    {formatrupiah(jm.harga_diskon)}
                                  </span>{" "}
                                  <span className="badge text-bg-dark fw-bold">
                                    {idlapangan == "8"
                                      ? "OPENING/MEMBER"
                                      : " SOFT OPENING"}
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* END satujam */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loadjambooking />
        )}

        {chekout == 1 ? (
          <></>
        ) : (
          <>
            {load ? (
              <div className={pagebooking ? "" : "d-none"}>
                <div
                  className={`card my-3 shadow ${
                    alerttglsudahlewat ? "d-none" : ""
                  }`}
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <p className="fw-bold">Form Booking </p>
                    <p>
                      Pembayaran booking lapangan hanya dapat di lakukan dengan
                      <strong> BANK TRANSFER</strong> dengan nomor rekening
                      tujuan
                      <strong>
                        {" "}
                        REK BCA : 6475383951 a/n Pendy Or Handoko,{" "}
                      </strong>{" "}
                      Pembayaran Booking Lapangan <strong>Wajib DP 50% </strong>
                      dari Harga yang sudah di tentukan, dengan syarat pelunasan{" "}
                      <strong>(H-1) 1 hari sebelum jadwal main</strong>
                    </p>
                    <div className="row mb-3 text-secondary">
                      <div className="form-group mt-3 col-md-6">
                        <label>Lapangan</label>
                        <input
                          type="text"
                          value={namaLapangan}
                          className="form-control mt-3 fw-bold"
                        />
                      </div>
                      <div className="form-group  mt-3 col-md-6">
                        <label>Harga Lapangan</label>
                        <br />
                        <input
                          type="text"
                          value={totalHarga}
                          placeholder="xxxxxxx"
                          className="form-control mt-3 fw-bold "
                        />
                      </div>
                    </div>
                    <div className="row mb-3 text-secondary">
                      <div className=" col-md-6 form-group mt-3 text-secondary">
                        <p className="text-secondary">Pembayaran</p>
                        <div class="form-check form-check-inline">
                          <input
                            className="form-check-input border-primary"
                            type="radio"
                            name="inlineRadioOptions"
                            id="lunas"
                            onClick={() => setSistempembayaran("Lunas")}
                            value="Lunas"
                          />

                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            Lunas
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input border-primary"
                            type="radio"
                            name="inlineRadioOptions"
                            id="dp"
                            onClick={() => setSistempembayaran("Dp")}
                            value="DP"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            DP 50%
                          </label>
                        </div>
                      </div>

                      <div className=" col-md-6 form-group mt-3 text-secondary">
                        <label className="mb-2">Transfer</label>
                        <input
                          type="text"
                          className="form-control fw-bold"
                          required=""
                          value={
                            sistempembayaran == "Dp"
                              ? totalHarga / 2
                              : totalHarga
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3 text-secondary">
                      <div className=" col-md-6 form-group mt-3 text-secondary">
                        <label className="mb-2">Nama Team</label>
                        <input
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="Sinar FC"
                          value={namateam}
                          onChange={(e) => setNamateam(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-6 mt-3 text-secondary">
                        <label className="mb-2">No Whatsapp</label>
                        <input
                          type="number"
                          className="form-control"
                          required=""
                          placeholder="08xxxxxxxx"
                          value={wa}
                          onChange={(e) => setWa(e.target.value)}
                        />
                        <small
                          className="text-primary"
                          style={{ fontSize: "10px" }}
                        >
                          Masukan nomor Whatsapp anda dengan benar untuk
                          mendapatkan notifikasi pembayaran anda
                        </small>
                      </div>
                    </div>
                    {hiddenbutton}
                    <div className="form-group mt-3">
                      {btnclose == true &&
                      hiddenbutton == true &&
                      namateam != "" &&
                      wa != "" &&
                      sistempembayaran != "" &&
                      totalHarga != "" ? (
                        <>
                          <Pembayaran
                            idlapangan={idlapangan}
                            lapangan={namaLapangan}
                            harga={totalHarga}
                            namateam={namateam}
                            jambooking={idjambooking}
                            wa={wa}
                            tgl={tglsrc}
                            totalharga={totalHarga}
                            sistempembayaran={sistempembayaran}
                          />
                        </>
                      ) : (
                        <button
                          className="btn  w-100 rounded-pill "
                          disabled
                          style={{ backgroundColor: "#2b2e5a", color: "white" }}
                        >
                          Booking sekarang
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loadformbooking />
            )}
          </>
        )}
      </div>

      {/* ----- */}

      <ToastContainer />
      <div className="mt-5">
        {hiddenbutton == false || hiddenbutton == false ? (
          ""
        ) : (
          <>
            {chekout == 1 ? (
              <>
                {" "}
                <Footerbooking
                  harga={totalHarga}
                  idlap={idlapangan}
                  idjam={idjambooking}
                  tglsrc={tglsrc}
                  statusbtn={statusButton}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}
