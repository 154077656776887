import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Combukti({ kodebooking }) {
    const urlapi = process.env.REACT_APP_BASE_URL
    const [bukti, setBukti] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const getdata = async () => {
        try {
            const response = await axios.get(
                urlapi + "Getbuktipembayaran?kode="+kodebooking
            );
            setBukti(response.data)
        
        } catch (error) {
        
         }
    }

    const showimage = () => {
        getdata();
        setModalShow(true);
    }

    
    
  return (
      <div>
         <Button variant="primary w-100 rounded-pill mt-3 btn-sm" onClick={() => showimage()} style={{ backgroundColor: "#2b2e5a", color: "white" }}>
            Bukti pembayaran anda
          </Button>
          
          <Modal
            show={modalShow}
                onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Bukti pembayaran anda
        </Modal.Title>
      </Modal.Header>
              <Modal.Body>
                  <center>
                      <img src={bukti.bukti} className='img-fluid' style={{ height: '400px' }} />
                      </center>
      </Modal.Body>
      <Modal.Footer>
        <Button className=''  onClick={() => setModalShow(false)} style={{ backgroundColor: "#2b2e5a", color: "white" }}>Close</Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}
