import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../componenst/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Compjmlpemain from "../componenst/Comprankkompetition/Compjmlpemain";
import Compwinrate from "../componenst/Comprankkompetition/Compwinrate";
import Compprofil from "../componenst/Comprankkompetition/Compprofil";

export default function Ranksolo() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [gamesolo, setGamesolo] = useState([]);
  const [rank, setRank] = useState([]);
  const [setuju, setSetuju] = useState(false);

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const [tglday, setTglday] = useState(format_tgl);

  const mainranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Getfungame");
      // console.log(response.data);
      setGamesolo(response.data);
    } catch (error) {
      // console.log(error.message);
    }
  };

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarir?rank=true");
      setRank(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("Anda dalam mode tamu, silahkan buat akun anda sekarang !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  const resvonsive3 = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 2,
    },
  };

  const cekperaturan = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Peraturan?ranksolo=true&&id_user=" +
          localStorage.getItem("id")
      );
      console.log(response.data);
      if (response.data !== "") {
        setSetuju(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    mainranksolo();
    getdata();
    cekperaturan();
  }, []);

  return (
    <div>
      <Navbar judul="Rank solo" aicon="true" />

      <div className="card">
        <div className="card-body">
          <br />
          <br />

          <img
            src="./img/cardrs.png"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          ></img>

          <div className="card border-0 mt-2" id="bggradient1">
            <div className="card-body text-white">
              <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
              <small>
                Selamat datang di Rank solo, temukan teman bermain anda di rank
                solo
              </small>
            </div>
          </div>

          {gamesolo == "" || tglday > gamesolo.tgl_main ? (
            <>
              {" "}
              <div className="alert alert-danger border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank solo ditutup,
                  cobalah mendaftar dilain hari{" "}
                </small>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="alert alert-primary border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank solo dibuka, buruan
                  daftar sekarang juga{" "}
                </small>
              </div>
            </>
          )}
        </div>

        <div className="container">
          <div className="d-flex justify-content-between">
            <small className="text-secondary fw-bold">
              <i className="fas fa-user"></i> Menu rank solo
            </small>

            <small className="text-secondary fw-bold">
              <i className="fas fa-ellipsis-vertical text-secondary"></i>
            </small>
          </div>

          <div className="card mt-2 mb-2  shadow">
            <div className="card-body">
              <div
                className="row mt-2 fw-bold"
                style={{ fontSize: "10px", color: "#2b2e5a" }}
              >
                <div className="col-sm-4 col-4">
                  {localStorage.getItem("tamu") !== null ? (
                    <>
                      <center>
                        <div onClick={() => notify()}>
                          <img
                            src="./img/menu/msolo.png"
                            className="img-fluid mb-2"
                            style={{
                              borderRadius: "10px",
                              height: "50px",
                            }}
                          ></img>
                          <br />
                        </div>
                        <small>Main rank solo</small>
                      </center>
                    </>
                  ) : (
                    <>
                      <center>
                        {setuju == true ? (
                          <>
                            {" "}
                            <Link to="/memberkarirbaru">
                              <img
                                src="./img/menu/msolo.png"
                                className="img-fluid mb-2"
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}
                              ></img>
                              <br />
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="/peraturanranksolo">
                              <img
                                src="./img/menu/msolo.png"
                                className="img-fluid mb-2"
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}
                              ></img>
                              <br />
                            </Link>
                          </>
                        )}

                        <small>Main rank solo</small>
                      </center>
                    </>
                  )}
                </div>

                <div className="col-sm-4 col-4">
                  <center>
                    <Link to="/rankingsolo">
                      <img
                        src="./img/menu/msolo2.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Ranking solo</small>
                  </center>
                </div>

                <div className="col-sm-4 col-4 ">
                  <center>
                    <Link to="/hasilranksolo">
                      <img
                        src="./img/menu/msolo3.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                      <br />
                    </Link>
                    <small>Hasil match</small>
                  </center>
                </div>

                <div className="col-sm-4 col-4 mt-3 ">
                  <center>
                    <Link to="/history">
                      <img
                        src="./img/menu/msolo4.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>History match anda</small>
                  </center>
                </div>

                <div className="col-sm-4 col-4 mt-3 ">
                  <center>
                    <Link to="/databookingranksolo">
                      <img
                        src="./img/menu/msolo5.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>History booking</small>
                  </center>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="">
              <small
                className="text-secondary fw-bold"
                style={{ fontSize: "12px" }}
              >
                <i className="fas fa-futbol"></i> Jadwal rank solo
              </small>
            </div>

            {gamesolo == false || tglday > gamesolo.tgl_main ? (
              <>
                <img
                  src="./img/cardsolo.png"
                  className="img-fluid mb-2 mt-2"
                  style={{
                    borderRadius: "10px",
                  }}
                ></img>
              </>
            ) : (
              <>
                <div className="card shadow">
                  <div className="card-body">
                    <div className="d-flex justify-content-between fw-bold">
                      <small className="fw-bold text-primary">
                        Sisa slot rank solo
                      </small>
                      <span class="badge text-bg-primary">
                        <Compjmlpemain
                          kode_main={gamesolo.kode_main}
                          jml={gamesolo.jml_pemain}
                        />
                      </span>
                    </div>
                    {localStorage.getItem("tamu") !== null ? (
                      <>
                        {" "}
                        <span
                          class="badge bg-success mt-2"
                          onClick={() => notify()}
                        >
                          <i className="fas fa-user"></i> Daftar sekarang
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span class="badge bg-success mt-2">
                          <Link
                            to="/memberkarirbaru"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <i className="fas fa-user"></i> Daftar sekarang
                          </Link>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={4}
                    nav={false}
                    responsive={resvonsive}
                    dotsEach={false}
                    dots={false}
                    autoplay={false}
                  >
                    <div className="card border-0" id="bggradient2">
                      <Link
                        to={
                          "/pemainmemberkarir/" +
                          gamesolo.kode_main +
                          "/Hitam/Putih/all"
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card-body ">
                          <div
                            className="d-flex justify-content-between fw-bold"
                            style={{ fontSize: "10px" }}
                          >
                            <small className="fw-bold">
                              <i className="far fa-user"></i> Hitam vs Hijau
                            </small>
                            <small className="fw-bold">
                              {" "}
                              <i className="far fa-clock"></i> Sesi 1
                            </small>
                          </div>
                          <hr style={{ color: "" }} />
                          <label
                            className="text-dark fw-bold"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="far fa-futbol"></i>{" "}
                            {gamesolo.lapangan}
                          </label>{" "}
                          <br />
                          <div
                            className="d-flex justify-content-between fw-bold"
                            style={{ fontSize: "10px" }}
                          ></div>
                          <label
                            className="text-secondary fw-bold"
                            style={{ fontSize: "10px" }}
                          >
                            <i className="far fa-clock"></i>{" "}
                            {gamesolo.jam_mulai} -{" "}
                            {parseInt(gamesolo.jam_mulai) + 1}.00 WIB
                          </label>{" "}
                          <label
                            className="text-secondary fw-bold"
                            style={{ fontSize: "10px" }}
                          >
                            <i className="far fa-calendar-days"></i>{" "}
                            {gamesolo.tgl_main}
                          </label>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="card border-0" id="bggradient2">
                      <Link
                        to={
                          "/pemainmemberkarir/" +
                          gamesolo.kode_main +
                          "/Merah/Kuning/all"
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card-body">
                          <div
                            className="d-flex justify-content-between  fw-bold"
                            style={{ fontSize: "10px" }}
                          >
                            <small className="fw-bold">
                              {" "}
                              <i className="far fa-user"></i> Merah vs Kuning
                            </small>
                            <small className="fw-bold">
                              <i className="far fa-clock"></i> Sesi 2
                            </small>
                          </div>
                          <hr />
                          <label
                            className="text-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            <i className="far fa-futbol"></i>{" "}
                            {gamesolo.lapangan}
                          </label>
                          <br />
                          <label
                            className="text-dark"
                            style={{ fontSize: "10px" }}
                          >
                            <i className="far fa-clock"></i>{" "}
                            {parseInt(gamesolo.jam_mulai) + 1}.00 -{" "}
                            {gamesolo.jam_selesai} WIB
                          </label>{" "}
                          <label
                            className="text-dark"
                            style={{ fontSize: "10px" }}
                          >
                            <i className="far fa-calendar-days"></i>{" "}
                            {gamesolo.tgl_main}
                          </label>{" "}
                          <br />
                        </div>
                      </Link>
                    </div> */}
                  </OwlCarousel>
                </div>
              </>
            )}
          </div>
          <hr />
          <div className="mt-2">
            <div className="d-flex justify-content-between fw-bold">
              <small
                className="fw-bold text-secondary"
                style={{ fontSize: "12px" }}
              >
                <i className="fas fa-star"></i> Top ranking rank solo
              </small>

              <small
                className="fw-bold text-secondary"
                style={{ fontSize: "12px" }}
              >
                <Link
                  className="text-secondary"
                  to="/rankingsolo"
                  style={{ textDecoration: "none" }}
                >
                  Lihat semuanya <i className="fas fa-angle-right"></i>
                </Link>
              </small>
            </div>
            {rank != false ? (
              <div>
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={4}
                  nav={false}
                  responsive={resvonsive3}
                  dotsEach={false}
                  dots={true}
                  autoplay
                >
                  {rank.slice(0, 5).map((data, index) => {
                    return (
                      <div key={index}>
                        <div
                          class="card shadow mt-3"
                          key={index}
                          style={{ border: "3px solid #2b2e5a" }}
                        >
                          <Compprofil id_user={data.id_user} />

                          <div
                            className="card-body"
                            style={{
                              backgroundColor: "#2b2e5a",
                              color: "white",
                            }}
                          >
                            <div>
                              <h5 className="card-title fw-bold">
                                {data.nama.length > 15 ? (
                                  <>
                                    {data.nama.toUpperCase().substring(0, 15)}{" "}
                                    ...
                                  </>
                                ) : (
                                  <> {data.nama.toUpperCase()}</>
                                )}
                                <div></div>
                                <label
                                  style={{
                                    lineHeight: "2px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Position : Player
                                </label>{" "}
                              </h5>
                            </div>

                            <div className="d-flex justify-content-between">
                              <span className="badge bg-warning text-dark fw-bold">
                                Play : {data.play}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold ml-2">
                                Win : {data.win}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold ml-2">
                                Draw : {data.draw}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold  ml-2">
                                Lost : {data.lost}
                              </span>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-6 col-6">
                                <div
                                  className="card"
                                  style={{
                                    borderColor: "",
                                    border: "2px solid  yellow",
                                    backgroundColor: "#2b2e5a",
                                    color: "white",
                                  }}
                                >
                                  <div className="card-body">
                                    <h5>Rank</h5>
                                    <p>
                                      {index + 1} ({data.point} Point)
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-6">
                                <div
                                  className="card"
                                  style={{
                                    borderColor: "",
                                    border: "2px solid yellow",
                                    backgroundColor: "#2b2e5a",
                                    color: "white",
                                  }}
                                >
                                  <div className="card-body">
                                    <h5>Winrate</h5>
                                    <Compwinrate id_user={data.id_user} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            ) : (
              <>
                <div className="card shadow  mt-2" style={{ border: "none" }}>
                  <div className="card-body">
                    <small className="text-secondary">
                      Main dengan teman barumu di medan mini soccer, Ayooo
                      buruan mainkan rank solo anda sekarang{" "}
                    </small>
                  </div>
                </div>

                <div className="card mt-2" id="bgranksolo">
                  <img src="img/rl2.png" className="img-fluid rounded" alt="" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
