import axios from "axios";
import React, { useEffect, useState } from "react";
import Compnama from "./Compnama";

export default function Comptopscore2() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [topscore, setTopscore] = useState([]);
  const [page, setPage] = useState(false);
  const [jml, setJml] = useState(10);

  const getTopscore = async () => {
    try {
      const response = await axios.get(
        urlapi + "Gettopscorememberkarir?rank=false"
      );
      setTopscore(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == true) {
      setPage(true);
      setJml(500000);
    } else {
      setPage(false);
      setJml(10);
    }
  };

  useEffect(() => {
    getTopscore();
  }, []);

  return (
    <div>
      <hr />

      {page == false ? (
        <>
          {" "}
          <small className="text-secondary">Posisi top score 10 besar</small>
        </>
      ) : (
        <small className="text-secondary">Posisi top score rank solo</small>
      )}

      <div className="table-responsive">
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr className="table-primary">
              <th scope="col">No</th>
              <th scope="col">Nama</th>
              <th scope="col">Goal</th>
            </tr>
          </thead>
          <tbody>
            {topscore.map((data, index) => {
              return (
                <tr
                  key={index}
                 
                >
                  <th id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold" scope="row">{index + 1}</th>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">
                    <Compnama iduser={data.id_user} />
                  </td>
                  <td id={index % 2 == 0 ? "genap" : "ganjil"} className="text-white fw-bold">{data.jml} Goal</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {page == false ? (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(true)}
            >
              Lihat semuanya <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        ) : (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(false)}
            >
              Tutup ranking <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        )}
      </div>
    </div>
  );
}
