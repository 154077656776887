import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import Comppelunasan from "../componenst/Pelunasan/Comppelunasan";
import Loadlapanganbooking from "../skeleton/Loadlapanganbooking";
import Combukti from "./Combukti";

export default function Databookinglapangan() {
  const [booking, setBooking] = useState([]);
  const [load, setLoad] = useState(false);
  const [valtgl, setValtgl] = useState("");
  const urlapi = process.env.REACT_APP_BASE_URL;

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Databookinglapangan?iduser=" + localStorage.getItem("id")
      );
      setBooking(response.data);
    } catch (error) {}
  };

  const getData2 = async (e) => {
    try {
      const response = await axios.get(
        urlapi +
          "Databookinglapangan?iduser=" +
          localStorage.getItem("id") +
          "&&tglbooking=" +
          e
      );
      setBooking(response.data);
      setLoad(true);
    } catch (error) {}
  };

  const notify = () => {
    toast.success("Booking berhasil dihapus !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const deleteData = async (id) => {
    await axios
      .post(urlapi + "Databookinglapangan", {
        id: id,
      })
      .then((response) => {
        getData();
        notify();
      });
  };

  const handlecaridata = async (e) => {
    setValtgl(e);
    setLoad(false);
    getData2(e);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
      getData();
    }, 5000);
  }, []);

  return (
    <div>
      {load == true ? (
        <>
          {" "}
          <small className="text-secondary">
            <i className="fas fa-magnifying-glass"></i> Cari berdasarkan tanggal
            booking
          </small>
          <input
            className="form-control border-pill mb-3 border border-primary"
            name="tgl"
            type="date"
            value={valtgl}
            onChange={(e) => handlecaridata(e.target.value)}
            style={{ borderRadius: "20px" }}
          ></input>
          {booking == "" ? (
            <div>
              <center>
                <img
                  src="/trash-bin.png"
                  className="img-fluid"
                  alt=""
                  style={{ height: "100px" }}
                ></img>
              </center>
              <p className="text-center text-secondary mt-3" style={{}}>
                <strong>Hay {localStorage.getItem("nama")}</strong>
                <br></br>Data booking anda kosong, silahkan booking sekarang
              </p>
            </div>
          ) : (
            <>
              {booking.map((data, index) => {
                return (
                  <div
                    className="card mb-3 shadow"
                    key={index}
                    style={{ border: "0px" }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 col-4">
                          <img
                            src="https://static.vecteezy.com/system/resources/previews/000/206/745/original/vector-isometric-soccer-stadium.png"
                            class="rounded float-start img-fluid h-100"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-sm-8 col-8">
                          <label>
                            <strong>{data.lapangan}</strong>
                          </label>{" "}
                          <br />
                          <small>
                            Hay, {localStorage.getItem("nama")} booking lapangan
                            anda{" "}
                            {data.status_pembayaran == 200
                              ? "Berhasil"
                              : "Tertunda"}
                          </small>
                          <br></br>
                          <hr />
                          <small className="text-primary">
                          Jam main : {data.jam_booking} WIB
                          </small>
                          <br />
                           <small className="text-primary">
                            Tanggal main : {data.tgl}
                          </small>
                          <p className="fw-bold text-danger">
                            <i className="fas fa-shield-halved"></i> : {data.team}
                          </p>
                          <hr></hr>
                          <p className="d-flex justify-content-between" style={{ fontSize: '10px' }}>
                            <small className="fw-bold">
                             Tanggal booking : {'  '}
                              {format_tgl == data.tgl ? "Hari ini" : data.tgl_booking}
                            </small>

                            <small className="fw-bold">
                              {data.status_pembayaran == 200
                                ? "Berhasil"
                                : "Tertunda"}
                            </small>
                            
                            {data.status_pembayaran == 200 ? (
                              ""
                            ) : (
                              <>
                                <small
                                  className="fw-bold text-danger"
                                  onClick={() => deleteData(data.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Cancel
                                </small>
                                {/* <i
                              className="fas fa-trash text-danger"
                              onClick={() => deleteData(data.id)}
                              style={{ cursor: "pointer" }}
                            ></i> */}
                              </>
                            )}
                          </p>
                        </div>
                      </div>

                      <Combukti  kodebooking = {data.kode_booking} />
                            
                      {data.status_pembayaran == 400 ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          {/* <Comppelunasan
                            kodebooking={data.kode_booking}
                            lapangan={data.lapangan}
                            tglbooking={data.tgl}
                            jambooking={data.jam_booking}
                          /> */}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <Loadlapanganbooking />
          <Loadlapanganbooking />
          <Loadlapanganbooking />
        </>
      )}
    </div>
  );
}
